/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:3fbbeafc-978f-4b63-a332-ad12301a9e2e",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_DZ9836HK9",
    "aws_user_pools_web_client_id": "5kr0l40bbnmsi2bp086pug5mde",
    "oauth": {}
};


export default awsmobile;
